import { useState } from 'react';

import create from 'zustand';
import { IMarket } from '../types';

type UseMarketStateProps = {
    market: IMarket;
    setMarket: (market: IMarket) => void;
};

export const useMarketState = create<UseMarketStateProps>((set) => ({
    market: {
        culture: 'da-dk',
        market: 'dk',
    },
    setMarket: (market) => set({ market }),
}));

export const convertCountryCodeToUppercase = (locale: string) => {
    const split = locale.split('-');
    return `${split[0]}-${split[1].toUpperCase()}`;
};

export const useMarket = () => {
    const { market } = useMarketState();
    const [isoCulture] = useState<string>(() => {
        if (!market.culture?.length) return '';
        return convertCountryCodeToUppercase(market.culture);
    });
    return { ...market, isoCulture };
};
